@font-face {
    font-family: 'Open sans';
    src: url('/assets/fonts/OpenSans/open-sans-300.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open sans';
    src: url('/assets/fonts/OpenSans/open-sans-400.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open sans';
    src: url('/assets/fonts/OpenSans/open-sans-500.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open sans';
    src: url('/assets/fonts/OpenSans/open-sans-600.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open sans';
    src: url('/assets/fonts/OpenSans/open-sans-700.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}